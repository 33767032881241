import { FirebaseAppSettings, initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGaoRG6wd__N8aMz6rKnWQ0zx3J-ANxZ8",
  authDomain: "l3rn-online-prod.firebaseapp.com",
  projectId: "l3rn-online-prod",
  storageBucket: "l3rn-online-prod.appspot.com",
  messagingSenderId: "515681052021",
  appId: "1:515681052021:web:ceb1325d6d3953e9fd5f3c"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig, <FirebaseAppSettings>{})

console.log('firebase app init')
// console.log(app)