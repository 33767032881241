





























































































import { UserNotificationVm } from '@/interfaces/UserNotificationVm';
import rest from '@/rest';
// import { vuex } from '@/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");
const notify = namespace("notify");

@Component
export default class UserMessages extends Vue {
  @notify.Action loadUserNotifications: any;
  @notify.Action userNotificationsNoted: any;

  @notify.Getter hasNewUserNotifications: any;
  @notify.Getter newUserNotificationCount: any;
  // @notify.Getter isPushSupportedByBrowser: any;

  showDialog = false;
  deleteMessagesbusy = false;

  @Watch('showDialog')
  async onShowdialogChanged(open) {
    if (open) {
      await this.loadUserNotifications();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
       // Dialog closing
      clearAllBodyScrollLocks();

      await this.userNotificationsNoted();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async onDelete(notification: UserNotificationVm) {
    await rest.url('notification/deleteUserNotification')
      .query({notificationId: notification.id}, true)
      .get();
    await this.loadUserNotifications();
  }

  async onDeleteAllMessages() {
    await rest.url('notification/deleteAllUserNotification')
      .get();
    await this.loadUserNotifications();
  }

  get HasNewUserNotifications() {
    return this.hasNewUserNotifications;
  }

  get NewUserNotificationCount() {
    const count = this.newUserNotificationCount;
    if (count > 99) {
      return '!';
    }
    return count.toLocaleString();
  }

  get userNotifications() {
    //return vuex.notify.userNotifications;
    return this.$store.state.notify.userNotifications;
  }

  get dlgBackgroundColor() {
    // let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
    // return this.$vuetify.theme.themes[currentThemeName].appBackground;

    return this.$vuetify.theme.dark ? "#505050" : "#F5F5F5";
  }
}
