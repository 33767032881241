import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import {
  extractVuexModule,
  createProxy,
  VuexModule,
} from "vuex-class-component";
import { RootStore } from "./types";
import { Auth } from "./modules/auth";
import { Ux } from "./modules/ux";
import { Globals } from "./modules/globals";
import { QuizPlayerStore } from "./modules/quizplayerstore";
import { Notify } from "./modules/notify";
import { ApprovalChat } from "./modules/approvalChat";

Vue.use(Vuex);

export const store = new Store({
  modules: {
    ...extractVuexModule(Auth),
    ...extractVuexModule(Ux),
    ...extractVuexModule(Globals),
    ...extractVuexModule(QuizPlayerStore),
    ...extractVuexModule(Notify),
    ...extractVuexModule(ApprovalChat),
  },
});

// Creating proxies.
export const vuex = {
  auth: createProxy(store, Auth),
  ux: createProxy(store, Ux),
  globals: createProxy(store, Globals),
  quizplayerstore: createProxy(store, QuizPlayerStore),
  notify: createProxy(store, Notify),
  approvalChat: createProxy(store, ApprovalChat),
};

export default store;
