


































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { vuex } from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// component
import CompSignature from '@/components/ExerciseComponents/CompSignature.vue';
// interfaces
import { BundleApprovalVm } from "@/interfaces/BundleApprovalVm";

//const auth = namespace("auth");
// const globals = namespace("globals");

@Component({
  components: {
    CompSignature
  }
})
export default class UserApprovalDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() approval!: BundleApprovalVm;

  busyOnSave = false;
  editApproval: BundleApprovalVm | null = null;
  isSigned = false;

  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;

  // $refs!: {
  //   signatureComponent: any;
  // };

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      await this.updateView();
      this.editApproval = _.cloneDeep(this.approval);

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  @Watch("editApproval.approved")
  onApproval(approved: boolean) {
    if (!this.editApproval)
      return;

    if (!approved) {
      this.editApproval.signature = null;
      this.editApproval.comment = null;
    }
  }

  onSigned(signature: string) {
    if (!this.editApproval)
      return;

    this.isSigned = true;
    this.editApproval.signature = signature;
    // if (isSigned) {
    //   this.editApproval.signature = this.$refs.signatureComponent.getSignature();
    // }
  }

  get disableSaveButton() {
    if (!this.editApproval)
      return true;
    if (!this.editApproval.approved)
      return false;
    if (!this.editApproval.exBundle?.bundleApprovalSignatureRequired)
      return false;

    return !this.editApproval.signature;
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async updateView() {
  }

  async updateApproval() {
    if (!this.editApproval)
      return;

    this.busyOnSave = true;

    await rest.url("bundleApproval/updateBundleApproval")
      .post(this.editApproval)
      .then((response) => {
        this.$emit("updateView:UserApprovalDlg");
        this.syncedShowDialog = false;
      })
      .finally(() => {
        this.busyOnSave = false;
      });
  }

  onCancel() {
    clearAllBodyScrollLocks();
    this.syncedShowDialog = false;
  }
}
