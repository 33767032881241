


















































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { vuex } from "@/store";
import { signalrEventBus } from '@/main';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// component
// interfaces
import { BundleApprovalVm } from "@/interfaces/BundleApprovalVm";
import { BundleApprovalChatVm } from "@/interfaces/BundleApprovalChatVm";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import moment from "moment";

//const auth = namespace("auth");
//const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class ApprovalChatDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() approval!: BundleApprovalVm;

  busyOnSend = false;
  busyOnDelete = false;
  newMessage: string = "";
  chatMessages: BundleApprovalChatVm[] | null = null;
  visualViewportHeight = 0;

  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;

  $refs!: {
    chattextfield: any;
  };

  mounted() {
    this.setWindowHeight();
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      this.chatMessages = null;
      await this.updateView();
      await this.$l3rnOnlineHub.ensureConnection();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  created() {
    // window.addEventListener("resize", this.setWindowHeight);
    window.visualViewport?.addEventListener("resize", this.setWindowHeight);
    signalrEventBus.$on('updateApprovalChat', this.onUpdateApprovalChat);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setWindowHeight);
    signalrEventBus.$off('updateApprovalChat', this.onUpdateApprovalChat);
    clearAllBodyScrollLocks();
  }

  // Event listener
  setWindowHeight() {
    // this.visualViewportHeight = window.innerHeight;
    this.visualViewportHeight = window.visualViewport?.height ?? 0;
    // this.$refs.chattextfield.focus();
    this.$globalHelper.animateScrollToBottom("chatContent", 500);
  }

  async onUpdateApprovalChat() {
    // console.log("ApprovalChatDlg.vue: onUpdateApprovalChat()");
    await this.loadChatList();
  }

  async updateView() {
    await this.loadChatList();
  }

  async loadChatList() {
    if (this.syncedShowDialog == false || !this.approval)
      return;

    this.chatMessages = await rest.url("bundleApproval/getApprovalChatList")
      .query({ bundleId: this.approval.exBundleId })
      .get();

    this.$emit("update:approvalChatDlg");
    this.$globalHelper.animateScrollToBottom("chatContent", 800);
  }

  async sendMessage() {
    if (!this.approval || !this.chatMessages)
      return;

    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["BundleId"] = this.approval.exBundleId.toString();
    params.dictionary!["Message"] = this.newMessage;

    this.busyOnSend = true;

    await rest.url("bundleApproval/sendApprovalChatMessage")
      .post(params)
      .then((response) => {
        // this.$emit("updateView:ApprovalChatDlg");
        this.newMessage = "";
        this.updateView();
        this.$refs.chattextfield.focus()
      })
      .finally(() => {
        this.busyOnSend = false;
      });
  }

  async deleteMessage(message: BundleApprovalChatVm) {
    this.busyOnDelete = true;
    await rest.url("bundleApproval/deleteApprovalChatMessage")
      .query({ messageId: message.id })
      .delete()
      .then((response) => {
        this.updateView();
      })
      .finally(() => {
        this.busyOnDelete = false;
      });
  }

  get dialogHeight() {
    if (this.$vuetify.breakpoint.xs) {
      let height = this.visualViewportHeight;
      return height + "px";
    }
    else
      return "100%";
  }

  get mobileContentClass() {
    if (this.$vuetify.breakpoint.xs)
      return "chatDialogFullScreen"

    return "";
  }

  messageColor(message: BundleApprovalChatVm) {
    // if (this.$vuetify.theme.dark) {
    //   if (message.isMine)
    //     return "#00ff0020";
    // }

    if (message.isMine)
      return "#00ff0020";
  }

  messageOffsetClass(message) {
    if (message.isMine)
      return "ml-4";

    return "mr-4";
  }

  onCancel() {
    clearAllBodyScrollLocks();
    this.syncedShowDialog = false;
  }

  toLocalDate(date: string): string {
    // let dateFormated = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let dateFormated = moment(date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return dateFormated;
  }
}
