/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}app-sw.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered () {
      console.log('Service worker has been registered.');
      // https://developers.google.com/web/tools/workbox/guides/codelabs/webpack
      // this.register.pushManager.subscribe({userVisibleOnly: true});
    },
    cached () {
      console.log('Content has been cached for offline use.');
      // window.alert("Content has been cached for offline use.");
    },
    updatefound () {
      console.log('New content is downloading.');
      // window.alert("New content is downloading.");
    },
    updated () {
      console.log('New content is available. The app will be refreshed now.');
      window.alert("Ein Update ist verfügbar. Die Anwendung wird nun aktualisiert.");
      location.reload();
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
      window.alert("Error during service worker registration. " + error);
    }
  });
}
